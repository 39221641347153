export const isBrowser = () => typeof window !== "undefined";

export const getUser = () => (isBrowser() && window.localStorage.getItem("gatsbyUser") ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : {});

const setUser = (user) => window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const handleLogin = ({ username, password }) => {
  if (username === `bridge5` && password === `amssasia`) {
    return setUser({
      username: `bridge5`,
      name: `bridge5`,
      email: `develop@bridge5.asia`,
    });
  }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.username;
};

export const logout = (callback) => {
  setUser({});
  callback();
};
